import firebase from 'firebase/app';
require("firebase/firestore");
require("firebase/analytics");
require("firebase/auth");
require("firebase/storage");


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZeUABYM5BVvuU8RTuroxCKmO3wLLZZLU",
  authDomain: "best-whatsappstoreever.firebaseapp.com",
  databaseURL: "https://best-whatsappstoreever.firebaseio.com",
  projectId: "best-whatsappstoreever",
  storageBucket: "image.cosine.store",
  messagingSenderId: "1054039111433",
  appId: "1:1054039111433:web:14c414e43ca64606f2c5d8",
  measurementId: "G-B3JTZRVNFT"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const storage = firebase.storage();
const db = firebaseApp.firestore();
const an = firebase.analytics();


export {
  an,
  db,
  auth,
  storage
}